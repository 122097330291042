import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconBtnComponent } from '@enginuity/core/molecules/icon-btn/icon-btn.component';

@Component({
  selector: 'app-media-tile',
  imports: [CommonModule, IconBtnComponent],
  templateUrl: './media-tile.component.html',
  styleUrls: ['./media-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaTileComponent {
  @Input() image: string = '/assets/product.jpg';
  @Input() enableDelete: boolean = false;
  @Input() index: number = 1;

  @Output() delete = new EventEmitter();
  @Input() temporary?: boolean = false;
  @Input() error: any = undefined;

  get imageUrl() {
    return `url(${this.image})`;
  }
}
