<div
  cdkDropList
  cdkDropListOrientation="mixed"
  (cdkDropListDropped)="imageDrop($event)"
  class="gallery">
  @for (image of images; track image; let i = $index) {
    <div class="gallery-entry" cdkDrag>
      <app-media-tile
        [enableDelete]="enableDelete"
        [image]="image.url_standard"
        [temporary]="image.temporary"
        [index]="i"
        (delete)="onEntryDelete(image)"></app-media-tile>
    </div>
  }
</div>
