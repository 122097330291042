<div class="image-tile" [style.background-image]="imageUrl" [attr.tabindex]="-1">
  <div class="image-tile-header">
    <div class="image-tile-header-index white-background">
      {{ index + 1 }}
    </div>
  </div>
  <div class="image-tile-body">
    <div class="image-tile-body-delete" *ngIf="enableDelete">
      <app-icon-btn iconType="delete" (click)="delete.emit()"></app-icon-btn>
    </div>
    <div class="image-tile-body-uploading" *ngIf="temporary && !error">Uploading ...</div>
    <div class="image-tile-body-error" *ngIf="error">Error uploading</div>
  </div>
</div>
